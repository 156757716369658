import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth-context";

function AuthCallback() {
  const { userManager, setUser, setIsLoading } = useAuth();
  const navigate = useNavigate(); // Make sure to import useNavigate

  useEffect(() => {
    const handleSignIn = async () => {
      try {
        setIsLoading(true); // Set loading state
        const user = await userManager.signinRedirectCallback();

        //const user = await userManager.signinSilent();
        const access_token = user.access_token;
        console.log(access_token);
        const returnPath =
          user && user.state && user.state.returnPath
            ? user.state.returnPath
            : "/";
        setIsLoading(false);
        navigate(returnPath); // Set isLoading to false if user exists
      } catch (error) {
        console.log(error.response);
        if (error && error.message === "invalid_grant") {
          // navigate("/login");
        } else {
          console.error("Error during sign-in callback:", error);
        }

        // navigate("/error");
      }
    };
    handleSignIn();
  }, [userManager, navigate]);

  return <div>Loading...</div>;
}

export default AuthCallback;
