import "./leftBar.scss";
import React from "react";
import { useNavigate, useLocation, matchRoutes } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import "./leftBar.scss";
import SidebarToggle from "../navbar/SidebarToggle";
import freedomLogo from "../../assets/img/logo/freedom/logo.svg";
import healthSunLogo from "../../assets/img/logo/healthsun/logo.svg";
import optimumLogo from "../../assets/img/logo/Optimum/logo.svg";
import healthSunLogoIcon from "../../assets/img/healthsun-icon.svg";
import freedomLogoIcon from "../../assets/img/freedom-icon.svg";
import optimumLogoIcon from "../../assets/img/optimum-icon.svg";

const LeftBar = () => {
  const { user, userRole } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (event, to) => {
    event.preventDefault();
    if (!user) {
      // if user is not authenticated, navigate to logout or login
      navigate("/post-logout");
    } else {
      // if user is authenticated, proceed with navigation
      navigate(to);
    }
  };
  const useCurrentPath = (to) => {
    const location = useLocation().pathname;

    return location === to;
  };
  const isCurrentPath = (to) => location.pathname === to;
  const isAboutMePath = useCurrentPath("/PatientSearch");
  const isRootPath = useCurrentPath("/");
  const isUserSearchPath = isCurrentPath("/UserSearch");

  const clientName = process.env.REACT_APP_ClientName;
  const getLogoSource = () => {
    switch (clientName) {
      case "freedom":
        return `${freedomLogo}`;
      case "healthsun":
        return `${healthSunLogo}`;
      case "optimum":
        return `${optimumLogo}`;
      default:
        return "";
    }
  };
  const getLogoIcon = () => {
    switch (clientName) {
      case "freedom":
        return `${freedomLogoIcon}`;
      case "healthsun":
        return `${healthSunLogoIcon}`;
      case "optimum":
        return `${optimumLogoIcon}`;
      default:
        return "";
    }
  };
  return (
    <>
      <nav className="sb-sidenav accordion" id="sidenavAccordion">
        <div className="header-section d-flex justify-content-between align-items-center">
          <div className="client-logo">
            <img
              src={getLogoSource()}
              alt="Client Logo"
              href="/PatientSearch"
              onClick={(e) => handleNavigation(e, "/PatientSearch")}
            />
          </div>
          <SidebarToggle />

          <img
            src={getLogoIcon()}
            alt="Client Logo"
            className="client-icon"
            href="/PatientSearch"
            onClick={(e) => handleNavigation(e, "/PatientSearch")}
          />
        </div>

        <div className="sb-sidenav-menu">
          <div className="nav">
            {/* <a
              className={"nav-link " + (useCurrentPath("/") ? "active" : "")}
              href='/'
              onClick={(e) => handleNavigation(e, "/")}
            >
              <div className='sb-nav-link-icon'>
                <i className='fa fa-user-alt'></i>
              </div>
              <span>Dashboard</span>
            </a> */}
            <a
              className={
                "nav-link " + (isAboutMePath || isRootPath ? "active" : "")
              }
              href="/PatientSearch"
              onClick={(e) => handleNavigation(e, "/PatientSearch")}
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              title="PatientSearch"
            >
              <div className="sb-nav-link-icon">
                <i className="fh_patient_search_2"></i>
              </div>
              <span>Patient Search</span>
            </a>
            {/* <a
              className={
                "nav-link " + (useCurrentPath("/PatientSearch") ? "active" : "")
              }
              href='/PatientSearch'
              onClick={(e) => handleNavigation(e, "/PatientSearch")}
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='PatientSearch'
            >
              <div className='sb-nav-link-icon'>
                <i className='fh_summary'></i>
              </div>
              <span>Patient Search</span>
            </a> */}
            {userRole === 1 && (
              <a
                className={"nav-link " + (isUserSearchPath ? "active" : "")}
                href="/UserSearch"
                onClick={(e) => handleNavigation(e, "/UserSearch")}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="User Search"
              >
                <div className="sb-nav-link-icon">
                  <i className="fh_user_search_1"></i>
                </div>
                <span>User Search</span>
              </a>
            )}
            {/* <a
              className={
                "nav-link " + (useCurrentPath("/Claim") ? "active" : "")
              }
              href='/Claim'
              onClick={(e) => handleNavigation(e, "/Claim")}
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Claims'
            >
              <div className='sb-nav-link-icon'>
                <i className='fh_summary'></i>
              </div>
              <span>Claims</span>
            </a>
            <a
              className={
                "nav-link " + (useCurrentPath("/Provider") ? "active" : "")
              }
              href='/Provider'
              onClick={(e) => handleNavigation(e, "/Provider")}
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Provider Directory'
            >
              <div className='sb-nav-link-icon'>
                <i className='fh_dr_specialist'></i>
              </div>
              <span>Provider Directory</span>
            </a>
            <a
              className={
                "nav-link " + (useCurrentPath("/Formulary") ? "active" : "")
              }
              href='/Formulary'
              onClick={(e) => handleNavigation(e, "/Formulary")}
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Formulary Directory'
            >
              <div className='sb-nav-link-icon'>
                <i className='fh_bottle_med_2'></i>
              </div>
              <span>Formulary Directory</span>
            </a>
            <a
              className={"nav-link " + (useCurrentPath("/Lab") ? "active" : "")}
              href='/Lab'
              onClick={(e) => handleNavigation(e, "/Lab")}
              data-bs-toggle='tooltip'
              data-bs-placement='right'
              title='Lab Result'
            >
              <div className='sb-nav-link-icon'>
                <i className='fh_lab'></i>
              </div>
              <span>Lab Result</span>
            </a> */}
          </div>
        </div>
        <div className="sb-sidenav-footer">
          <div className="footer-info">
            <div className="small-txt">Powered By</div>
            <img
              src={require("../../assets/img/aaneel-poweredby.svg").default}
              alt="mySvgImage"
            />
          </div>
        </div>
      </nav>
    </>
  );
};

export default LeftBar;
