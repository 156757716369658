import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchToken } from "../../config/blueButtonOAuthConfig"; // Import the function for fetching the token
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { dbInstance } from "../../api/axios";
import { useAuth } from "../../context/auth-context";

const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    console.log(`user accesstoken: ${user}`);
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
}

const BlueButtonCallback = () => {
  const navigate = useNavigate();
  const { setBlueButtonToken } = useAuth();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const error = urlParams.get("error");
    const errorDescription = urlParams.get("error_description");

    if (code) {
      // Fetch the token using the authorization code
      fetchToken(code)
        .then(async (userData) => {
          // Set the BlueButton access token in the context
          if (userData && userData.access_token) {
            setBlueButtonToken(userData.access_token);
          }

          navigate("/claim", { state: { fromBlueButton: true } });
        })
        .catch((fetchError) => {
          console.error("Error when fetching the token:", fetchError);

          // Here, you can handle failures after an unsuccessful token fetch. This might involve
          // redirecting the user to an error page, showing an error message, etc.
          // For instance:
          // navigate("/error");
        });
    } else if (error) {
      console.error(`OAuth Error: ${error}. Description: ${errorDescription}`);
    } else {
      console.error("Error: Authorization code not found!");
      // navigate("/login");
    }
  }, [navigate]);

  return (
    <div>
      <p>Loading...</p>
      {/* will add loading spinner later*/}
    </div>
  );
};

export default BlueButtonCallback;
