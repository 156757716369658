import "./navbar.scss";
// import logo from "../../assets/img/logo/freedom/Logo.png";
// import SidebarToggle from "./SidebarToggle";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import React, { useEffect, useState } from "react";
import userImg from "../../assets/img/avatar-04.svg";
import userladyImg from "../../assets/img/avatar-female.svg";
import {
  calculateAge,
  toTitleCase,
  formatDate,
  formatCurrency,
} from "../../utils/Utils";
const NavBar = () => {
  const { userManager } = useAuth();
  const navigate = useNavigate(); // Use navigate function
  const [zoomLevel, setZoom] = useState("100");
  const [userName, setUserName] = useState("");
  const [userGender, setUserGender] = useState("Male");
  const zLevel = 10;
  const noTimes = 3;
  const minZLevel = 100 - noTimes * zLevel;
  const maxZLevel = 100 + noTimes * zLevel;
  const increase = (e) => {
    e.preventDefault();
    let zoom = parseInt(
      document.body.style.zoom
        .toString()
        .substring(0, document.body.style.zoom.toString().length - 1)
    );
    if (zoom < maxZLevel) {
      document.body.style.zoom = `${zoom + zLevel}%`;

      document.getElementById("zoomLevel").innerText = `${zoom + zLevel}%`;
    }
    showHideZoom();
  };
  const decrease = (e) => {
    e.preventDefault();
    let zoom = parseInt(
      document.body.style.zoom
        .toString()
        .substring(0, document.body.style.zoom.toString().length - 1)
    );
    if (zoom > minZLevel) {
      document.body.style.zoom = `${zoom - zLevel}%`;

      document.getElementById("zoomLevel").innerText = `${zoom - zLevel}%`;
    }
    showHideZoom();
  };
  const resetZoom = () => {
    document.body.style.zoom = `100%`;
    document.getElementById("zoomLevel").innerText = `100%`;
    showHideZoom();
  };

  const showHideZoom = () => {
    document.getElementById("zoomLevel").classList.remove("d-none");
    setTimeout(() => {
      document.getElementById("zoomLevel").classList.add("d-none");
    }, 3000);
  };
  useEffect(() => {
    // Fetch the user's information from the access token
    let zoom = parseInt(
      document.body.style.zoom
        .toString()
        .substring(0, document.body.style.zoom.toString().length - 1)
    );
    document.getElementById("zoomLevel").setAttribute("title", `${zoom}%`);
    async function fetchUserName() {
      try {
        const user = await userManager.getUser();
        if (user) {
          // setUserGender(user.profile.gender);
          // Assuming that the user's name is stored in the 'name' field of the user object
          if (user.profile.fullname) {
            setUserName(user.profile.fullname);
          } else {
            setUserName(user.profile.given_name);
          }
        }
      } catch (error) {
        console.error("Error fetching user information:", error);
      }
    }

    fetchUserName();
  }, [userManager]);
  const handleLogout = async () => {
    try {
      // Navigate to custom logout component.
      navigate("/auth-callback-logout");
      // Set the logout event to trigger logout in all tabs
      localStorage.setItem("logout", Date.now());
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };
  const toggleSideBar = () => {
    const sidebarToggle = document.body.querySelector("#sidebarToggle");

    if (sidebarToggle) {
      document.body.classList.toggle("sb-sidenav-toggled");
    }
  };
  const displayusername = userName;
  return (
    <>
      <nav className="top-bar sb-topnav navbar navbar-expand navbar-light">
        <button
          className="btn btn-link btn-sm toggle-btn d-touch"
          onClick={toggleSideBar}
        >
          <i className="fh_menu_1"></i>
        </button>
        {/* <a className='navbar-brand ps-3' href='index.html'>
          <img src={logo} alt='Annel Care' width={"83%"} height={"64%"} />
        </a> */}
        <div className="container-fluid">
          <button
            className="navbar-toggler px-0"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarExample2"
            aria-controls="navbarExample2"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fh_menu_1"></i>
          </button>
          <div className="badge admin-badge-top">Admin Portal</div>
        </div>

        {/* <form className='d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0'>
          <div className='input-group' style={{ width: "305px" }}>
            <input
              className='form-control bg-light border-0 small'
              type='text'
              placeholder='Search for...'
              aria-label='Search for...'
              aria-describedby='btnNavbarSearch'
            />
            <button
              className='btn btn-primary'
              id='btnNavbarSearch'
              type='button'
            >
              <i className='fas fa-search'></i>
            </button>
          </div>
        </form> */}

        <ul className="navbar-nav">
          <li className="nav-item dropdown align-items-center d-flex">
            <a
              className="nav-link "
              role="button"
              href="#!"
              onClick={decrease}
              // data-bs-toggle='dropdown'
              aria-expanded="false"
            >
              <i className="fh_subtract"></i>
            </a>
            <a
              className="nav-link flex-column"
              role="button"
              href="#!"
              onClick={resetZoom}
              // data-bs-toggle='dropdown'
              aria-expanded="false"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
            >
              <i className="fh_zoom"></i>
              <div id="zoomLevel" className="d-none custom-none">
                {zoomLevel}%
              </div>
            </a>
            <a
              className="nav-link"
              onClick={increase}
              role="button"
              // data-bs-toggle='dropdown'
              href="#!"
              aria-expanded="false"
            >
              <i className="fh_add"></i>
            </a>
          </li>
          {/* <li className='nav-item dropdown align-items-center d-flex'>
            <a
              className='nav-link align-items-center d-flex p-0'
              href='#!'
              id='languageDropdown'
              role='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <a
                className='nav-link'
                role='button'
                // data-bs-toggle='dropdown'
                href='#!'
                aria-expanded='false'
              >
                <i className='fh_translate'></i>
              </a><span>Language</span><i className='fh_arrow_down_line position-relative top-2'></i>
            </a>
            <ul
              className='dropdown-menu dropdown-menu-end header-dropdown'
              aria-labelledby='languageDropdown'
            >
              <li>
                <a className='dropdown-item' href='#!'>
                  <i class='fas fa-power-off'></i> English
                </a>
              </li>
              <li>
                <a className='dropdown-item' href='#!'>
                  <i class='fas fa-power-off'></i> Spanish
                </a>
              </li>
            </ul>
          </li>
          <li className='nav-item dropdown align-items-center d-flex'>
            <a
              className='nav-link '
              href='#!'
              role='button'
              // data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <i className='fh_bell_fill'></i>
            </a>
          </li>
          <li className='nav-item dropdown align-items-center d-flex'>
            <a
              className='nav-link '
              href='#!'
              role='button'
              // data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <i className='fh_print'></i>
            </a>
          </li> */}

          <li className="nav-item dropdown img-section align-items-center d-flex">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              href="#!"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="d-flex align-items-center">
                <div className="img-info column d-none d-md-block">
                  <div className="welcome-info">Welcome Back!</div>
                  <div className="user-name">{userName}</div>
                </div>
                <span className="user-img">
                  {userGender === "Male" ? (
                    <img src={userImg} alt="User"></img>
                  ) : (
                    <img src={userladyImg} alt="User"></img>
                  )}
                </span>
              </div>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end header-dropdown"
              aria-labelledby="navbarDropdown"
            >
              <li className="d-block d-md-none">
                <a className="dropdown-item text-capitalize" href="#!">
                  <i class="fh_user absolute"></i>{" "}
                  <span className="ms-3">{userName}</span>
                </a>
              </li>
              <li>
                <Link
                  className="dropdown-item align-items-center d-flex"
                  to="/ChangePassword"
                >
                  <i class="fh_key_fill absolute"></i>
                  <span className="ms-3">Change Password</span>
                </Link>
              </li>
              {/* <li>
                <hr className='dropdown-divider' />
              </li> */}
              <li>
                <a
                  className="dropdown-item align-items-center d-flex"
                  href="#!"
                  onClick={handleLogout}
                >
                  <i class="fh_log_off absolute"></i>{" "}
                  <span className="ms-3">Logout</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default NavBar;
