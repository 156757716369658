import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "../src/context/auth-context"; // Import your AuthProvider
import "react-tooltip/dist/react-tooltip.css";

const root = document.getElementById("root");
const clientName = process.env.REACT_APP_ClientName;
if (root !== null) {
  const appRoot = ReactDOM.createRoot(root);
  document.body.style.zoom = "100%";
  document.body.classList.add(`theme-${clientName}`);
  appRoot.render(
    <React.StrictMode>
      <AuthProvider>
        <App />
      </AuthProvider>
    </React.StrictMode>
  );
}
