import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth-context";

const PostLogout = () => {
  const navigate = useNavigate();
  const { userManager } = useAuth();
  useEffect(() => {
    // Set a timer to redirect after 3 seconds
    const timer = setTimeout(() => {
      userManager.signinRedirect(); // Replace "/login" with the path to your login page
    }, 3000); // Adjust the delay as needed
    // Clean up the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, [navigate]);

  return <div>You have been logged out. Redirecting to login...</div>;
};
export default PostLogout;
