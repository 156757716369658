import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/auth-context";

function AuthCallbackSilent() {
  const { userManager } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    userManager.signinSilentCallback().catch((err) => {
      console.error("Silent Renewal Error:", err);
      setError(err.message || "Silent Renewal Error"); // Set the error state to display the error message to the user
    });
  }, [userManager]);

  if (error) {
    return <div>Error: {error}</div>; // Display error message if silent renewal fails
  }

  return <div>Loading...</div>;
}

export default AuthCallbackSilent;
