import React, { useState, useEffect } from "react";
import axios from "axios";
import * as yup from "yup";
import { useParams, useNavigate } from "react-router-dom";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import { dbInstance, ssoInstance } from "../../api/axios";
import { v4 as uuidv4 } from "uuid";

const UserDetail = () => {
  const schema = yup.object().shape({
    FirstName: yup.string().required("First name is required"),
    LastName: yup.string().required("Last name is required"),
    Email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    Username: yup.string().required("Username is required"),
    IsActive: yup.string().required("Active status is required"),
    RoleID: yup.string().required("Role is required"),
  });

  const initialUserData = {
    FirstName: "",
    LastName: "",
    Username: "",
    Email: "",
    IsActive: "true", // Assuming default is active
    RoleID: "",
  };

  const userManager = new UserManager(oidcConfig);
  const [user, setUser] = useState(initialUserData);
  const [errors, setErrors] = useState({});
  const { userId } = useParams();
  const navigate = useNavigate();
  const isEditMode = userId !== undefined;
  const [userAlert, setUserAlert] = useState({ type: "", message: "" });
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");

  async function getAccessToken() {
    try {
      const user = await userManager.getUser();
      if (!user) {
        throw new Error("User is not authenticated");
      }
      return user.access_token;
    } catch (error) {
      console.error("Error fetching access token:", error);
      throw error;
    }
  }
  const handleNavigation = (event, to) => {
    event.preventDefault();
    if (!user) {
      // if user is not authenticated, navigate to logout or login
      navigate("/post-logout");
    } else {
      // if user is authenticated, proceed with navigation
      navigate(to);
    }
  };
  // Fetching roles from database
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const accessToken = await getAccessToken();
        // const response = await dbInstance.get("/userRoles"); // Adjust with your actual endpoint
        const response = await dbInstance.get(`/userRoles`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setRoles(response.data);
      } catch (error) {
        console.error("Error fetching roles:", error);
        // Optionally set an error state or user alert here
      }
    };

    fetchRoles();
  }, []);
  useEffect(() => {
    if (isEditMode) {
      const fetchUser = async () => {
        try {
          const accessToken = await getAccessToken();
          const url = `/users/${userId}`;
          const response = await dbInstance.get(url, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          // Check if response contains expected data
          if (response.data) {
            const fetchedUser = response.data; // Directly using response data
            setUser({
              FirstName: fetchedUser.FirstName,
              LastName: fetchedUser.LastName, // Note the space in the key
              Username: fetchedUser.Username,
              Email: fetchedUser.Email,
              IsActive: fetchedUser.IsActive.toString(),
              RoleID: fetchedUser.RoleID,
            });
            setSelectedRole(
              fetchedUser.RoleID ? fetchedUser.RoleID.toString() : ""
            );
          } else {
            // Handle cases where the data isn't as expected
            console.error("Unexpected response structure:", response.data);
            // Set an error or handle this situation appropriately
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      };

      fetchUser();
    }
  }, [userId, isEditMode]);
  const sendEmail = async (email, password, Username, FirstName, LastName) => {
    try {
      const formDataObj = new FormData();
      const VisitUrl = process.env.REACT_APP_Visit_URL;
      const TeamName = process.env.REACT_APP_Team_name;
      const WelcomeTo = process.env.REACT_APP_Welcome_To;
      const htmlEmailTemplate = `<!DOCTYPE html>
      <html lang="en">
      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <style>
              body {font-family: Arial, sans-serif; color: #333;}
              .container {padding: 20px; border: 1px solid #ddd;}
              .header {color: #444; margin-bottom: 20px;}
              .footer {margin-top: 20px; font-size: 0.8em;}
          </style>
      </head>
      <body>
          <div class="container">
              <h1 class="header">Welcome to ${WelcomeTo}!</h1>
              <p>Dear ${FirstName} ${LastName},</p>
              <p>Your user account has been successfully created. To access all the features, please log in using the credentials provided below and set up a new password.</p>
              <p><strong>User ID:</strong>  ${Username}<br>
              <strong>Temporary Password:</strong> ${password}</p>
              <p>Here are the steps to set your new password:</p>
              <ol>
                  <li>Visit ${VisitUrl}</li>
                  <li>Log in to your account using the credentials provided above.</li>
                  <li>Navigate to the 'My Profile' then 'Change Password' section.</li>
                  <li>Follow the on-screen instructions to create a new, strong password.</li>
              </ol>
              <p><strong>Password Requirements:</strong></p>
              <ul>
                  <li>At least 8 characters long.</li>
                  <li>Include a mix of uppercase and lowercase letters.</li>
                  <li>Include numbers and special characters.</li>
                  <li>Avoid using easily guessable information like your name or common words.</li>
                  <li>Do not reuse previous passwords.</li>
              </ul>
              <p>We're excited to have you on board and look forward to supporting your healthcare management needs. If you encounter any difficulties or have questions, please reach out to your admin or manager.</p>
              <p class="footer">Please note: This email is generated automatically. Replies to this message are not monitored. For assistance, contact support directly.</p>
              <p>Warm regards,<br>The ${TeamName} Team</p>
          </div>
      </body>
      </html>`;

      formDataObj.append("emailImportance", "normal");
      formDataObj.append(
        "recipients",
        JSON.stringify({
          to: [{ emailAddress: email }],
        })
      );
      formDataObj.append(
        "emailContents",
        JSON.stringify({
          subject: "Your OTP for Registration",
          HTML: htmlEmailTemplate,
        })
      );
      formDataObj.append("emailSender", "DoNotReply@aaneel.com");
      const EmailEndPoint = process.env.REACT_APP_EMAILEND_POINT;
      const emailResponse = await axios.post(EmailEndPoint, formDataObj, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (emailResponse.status === 200) {
        console.log("Email sent successfully to:", email);
      } else {
        throw new Error("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      throw error; // Rethrow to handle in createUser
    }
  };
  const generatePassword = (length = 12) => {
    const numbers = "0123456789";
    const lowerCaseLetters = "abcdefghijklmnopqrstuvwxyz";
    const upperCaseLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const specialCharacters = "!@#$%^&*()_+~";
    const allCharacters =
      numbers + lowerCaseLetters + upperCaseLetters + specialCharacters;

    // Ensuring the password contains at least one of each required character type
    let password = [
      numbers[Math.floor(Math.random() * numbers.length)],
      lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)],
      upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)],
      specialCharacters[Math.floor(Math.random() * specialCharacters.length)],
    ].join("");

    // Filling the rest of the password length with random characters from all types
    for (let i = password.length; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * allCharacters.length);
      password += allCharacters[randomIndex];
    }

    // Shuffle the password to avoid predictable patterns (optional)
    password = password
      .split("")
      .sort(() => 0.5 - Math.random())
      .join("");

    return password;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the name is 'UserRole', update the selectedRole and user state
    if (name === "UserRole") {
      setSelectedRole(value); // Update selected role
      setUser((prevUser) => ({ ...prevUser, RoleID: value }));
    } else {
      const finalValue = name === "IsActive" ? e.target.checked : value;
      setUser((prevUser) => ({
        ...prevUser,
        [name]: finalValue,
      }));

      // Validate with yup schema for other fields except UserRole
      if (name !== "UserRole") {
        schema
          .validateAt(name, { ...user, [name]: finalValue })
          .then(() => {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: undefined,
            }));
          })
          .catch((err) => {
            setErrors((prevErrors) => ({
              ...prevErrors,
              [name]: err.message,
            }));
          });
      }
    }
  };
  const handleRoleChange = (e) => {
    const newRole = e.target.value;
    setSelectedRole(newRole);
    setUser((prevUser) => ({ ...prevUser, RoleID: newRole }));

    // Directly validate the RoleID field
    schema
      .validateAt("RoleID", { ...user, RoleID: newRole })
      .then(() => {
        // Correctly clear the error if validation is successful
        setErrors((prevErrors) => ({ ...prevErrors, RoleID: "" }));
      })
      .catch((err) => {
        // Set the error message from validation
        setErrors((prevErrors) => ({ ...prevErrors, RoleID: err.message }));
      });
  };

  // Define separate functions for updating and creating users
  const updateUser = async () => {
    try {
      const accessToken = await getAccessToken();
      console.log("Updating user with role:", user.RoleID);
      const response = await dbInstance.put(
        "/users",
        {
          ...user,
          UserID_PK: userId, // Ensure this is the correct field name and value
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        alert("User updated successfully.");
        // navigate("/user-search");
      } else {
        throw new Error("Failed to update user.");
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };
  const createUserInSSO = async () => {
    try {
      let ssoid = uuidv4();
      const password = generatePassword();
      const ssoPayload = {
        ID: ssoid,
        Clients: [
          `${process.env.REACT_APP_SSO_CLIENTS}`,
          `${process.env.REACT_APP_SSO_PatientPortalClient}`,
        ],
        EmailConfirmed: true,
        Username: user.Username,
        FullName: `${user.FirstName}, ${user.LastName}`,
        IsEnabled: true,
        password: password,
        Email: user.Email,
      };

      const ssoResponse = await ssoInstance.post(
        "api/user/CreateApplicationUserWithPassword",
        ssoPayload
      );

      // Check the 'succeeded' field in the response and handle accordingly
      if (ssoResponse.data && ssoResponse.data.succeeded === false) {
        const error = ssoResponse.data.errors.find(
          (e) => e.code === "DuplicateUserName"
        );
        if (error) {
          // Set user alert to show duplicate username error
          setUserAlert({ type: "error", message: error.description });
        } else {
          // Generic error handling for other error types
          setUserAlert({ type: "error", message: "SSO user creation failed." });
        }
        throw new Error("SSO user creation failed due to errors");
      }

      // If the SSO creation is successful, return the necessary data
      if (ssoResponse.data && ssoResponse.data.succeeded) {
        return { ssoid, password };
      }

      // Handle unexpected response structure
      throw new Error("Unexpected response structure from SSO service");
    } catch (error) {
      console.error("Error creating user in SSO:", error);
      if (!userAlert.message) {
        setUserAlert({
          type: "error",
          message: "Failed to create user in SSO.",
        });
      }
      throw error;
    }
  };

  const createUser = async () => {
    try {
      const accessToken = await getAccessToken();
      if (!accessToken) {
        alert("No access token found, user might not be authenticated.");
        return; // Exit the function if there's no token
      }

      // Check if the email already exists
      try {
        await dbInstance.get(`/users/userByEmail?email=${user.Email}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setErrors({ ...errors, Email: "Email already in use." });
        return;
      } catch (error) {
        if (error.response && error.response.status === 404) {
          const ssoUserData = await createUserInSSO(); // Attempt to create user in SSO
          console.log("SSOID", ssoUserData.ssoid);
          // Check if ssoUserData.id exists before proceeding
          if (ssoUserData && ssoUserData.ssoid) {
            const response = await dbInstance.post(
              "/users",
              {
                ...user,
                RoleID: selectedRole,
                UserID_SSO: ssoUserData.ssoid, // Include the UserID_SSO from SSO service
              },
              {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              }
            );

            if (response.status === 201) {
              await sendEmail(
                user.Email,
                ssoUserData.password,
                user.Username,
                user.FirstName,
                user.LastName
              );
              setUserAlert({
                type: "success",
                message: `User created successfully. An email has been sent to ${user.Email}.`,
              });
            } else {
              throw new Error("Failed to create user in local DB.");
            }
          } else {
            // Handle case where ssoUserData.id doesn't exist
            throw new Error("Failed to retrieve SSO User ID.");
          }
        } else {
          // Handle other errors or unexpected responses
          console.error("Error checking for duplicate email:", error);
          alert("Failed to check for duplicate email.");
        }
      }
    } catch (error) {
      console.error("Error creating user:", error);
      if (!userAlert.message) {
        setUserAlert({
          type: "error",
          message: `Failed to create user: ${error.message}`,
        });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUserAlert({ type: "", message: "" });
    try {
      await schema.validate(user, { abortEarly: false });

      // Separate the logic for updating and creating users
      if (isEditMode) {
        await updateUser();
      } else {
        await createUser();
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        // Handle validation errors
        const validationErrors = {};
        error.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setErrors(validationErrors);
      } else {
        console.error(
          `Error ${isEditMode ? "updating" : "creating"} user:`,
          error
        );
        alert(`Failed to ${isEditMode ? "update" : "create"} user.`);
      }
    }
  };
  const handleClear = () => {
    setUser(initialUserData);
  };
  useEffect(() => {
    if (setUserAlert.message) {
      const timer = setTimeout(
        () => setUserAlert({ type: "", message: "" }),
        5000
      ); // clears alert after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [setUserAlert]);
  return (
    <>
      <div className='main-content'>
        <div className='content-header d-flex justify-content-between align-items-center'>
          <h1 className='page-header'>{isEditMode ? "Edit" : "Create"} User</h1>
          <button
            type='button'
            href='/UserSearch'
            onClick={(e) => handleNavigation(e, "/UserSearch")}
            className='btn btn-border-primary h-48 d-flex align-items-center'
          >
            <i className='fh_arrow_left icon-mr fs-5'></i>
            <span>Back to User list</span>
          </button>
        </div>
        <div className='card'>
          <div className='card-body'>
            {userAlert.message && (
              <div
                className={`alert ${
                  userAlert.type === "error" ? "alert-danger" : "alert-success"
                }`}
                role='alert'
              >
                {userAlert.message}
              </div>
            )}
            <form onSubmit={handleSubmit}>
              <div className='form-content mt-0'>
                <div className='row'>
                  {/* First Name Field */}
                  <div className='col-sm-6 col-md-4 mb-3'>
                    <label htmlFor='FirstName' className='form-label'>
                      First Name
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='FirstName'
                      value={user.FirstName}
                      onChange={handleChange}
                      isInvalid={!!errors.FirstName}
                    />
                    <div className='invalid-feedback'>{errors.FirstName}</div>
                  </div>

                  {/* Last Name Field */}
                  <div className='col-sm-6 col-md-4 mb-3'>
                    <label htmlFor='LastName' className='form-label'>
                      Last Name
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='LastName'
                      value={user.LastName}
                      onChange={handleChange}
                      isInvalid={!!errors.LastName}
                    />
                    <div className='invalid-feedback'>{errors.LastName}</div>
                  </div>

                  {/* Username Field */}
                  <div className='col-sm-6 col-md-4 mb-3'>
                    <label htmlFor='Username' className='form-label'>
                      Username
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      name='Username'
                      value={user.Username}
                      onChange={handleChange}
                      isInvalid={!!errors.Username}
                    />
                    <div className='invalid-feedback'>{errors.Username}</div>
                  </div>

                  {/* Email Field */}
                  <div className='col-sm-6 col-md-4 mb-3'>
                    <label htmlFor='Email' className='form-label'>
                      Email
                    </label>
                    <input
                      type='email'
                      className='form-control'
                      name='Email'
                      value={user.Email}
                      onChange={handleChange}
                      isInvalid={!!errors.Email}
                    />
                    <div className='invalid-feedback'>{errors.Email}</div>
                  </div>
                  <div className='col-sm-6 col-md-4 mb-3'>
                    <label htmlFor='UserRole' className='form-label'>
                      Role
                    </label>
                    <div className='form-field position-relative'>
                      <select
                        className={`form-control ${
                          errors.RoleID ? "is-invalid" : ""
                        }`}
                        name='UserRole'
                        value={selectedRole}
                        onChange={handleRoleChange}
                      >
                        <option value=''>Select Role</option>
                        {roles.map((role) => (
                          <option key={role.RoleID_PK} value={role.RoleID_PK}>
                            {role.RoleName}
                          </option>
                        ))}
                      </select>
                      <span className='field-icon position-absolute top-50 translate-middle-y'>
                        <i className='fh_arrow_down_line'></i>
                      </span>
                      {errors.RoleID && (
                        <div className='invalid-feedback d-block'>
                          {errors.RoleID}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* IsActive Field */}
                  <div className='col-sm-6 col-md-4 mb-3 d-flex align-items-end'>
                    <div className='mb-2'>
                      <input
                        className='form-check-input me-2 flex-shrink-0 cursor-pointer'
                        type='checkbox'
                        id='IsActive'
                        name='IsActive'
                        checked={
                          user.IsActive === "true" || user.IsActive === true
                        } // Ensure correct boolean representation
                        onChange={handleChange}
                      />
                      <label
                        className='form-check-label mt-1 cursor-pointer'
                        htmlFor='IsActive'
                      >
                        Is Active
                      </label>
                    </div>
                    {errors.IsActive && (
                      <div className='invalid-feedback mb-2'>
                        {errors.IsActive}
                      </div>
                    )}
                  </div>
                </div>
                {/* Submit and Clear Buttons */}
                <div className='col-12 text-center'>
                  <button type='submit' className='btn btn-primary  h-48 me-3'>
                    {isEditMode ? "Update" : "Create"}
                  </button>
                  <button
                    type='button'
                    className='btn btn-border-primary h-48'
                    onClick={handleClear}
                  >
                    Clear
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetail;
