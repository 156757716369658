import React, { useState, useEffect, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { UserManager } from "oidc-client";
import { useNavigate } from "react-router-dom";
import oidcConfig from "../../config/oidc-config";
import { dbInstance } from "../../api/axios";
import { UserAddDateToMMDDYYYY } from "../../utils/Utils";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
}

const UserSearch = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [localPageSize, setLocalPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const [searchCriteria, setSearchCriteria] = useState({
    FirstName: "",
    LastName: "",
    Username: "",
    Email: "",
    IsActive: "",
  });

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "FirstName",
      },
      {
        Header: "Last Name",
        accessor: "LastName",
      },
      {
        Header: "Username",
        accessor: "Username",
        Cell: ({ row }) => (
          <span
            className='cursor-pointer primary text-decoration-underline text-capitalize'
            onClick={() => handleRowClick(row.original.UserID_PK)} // Assuming 'id' is the unique identifier
          >
            {row.values.Username}
          </span>
        ),
      },
      {
        Header: "User Role",
        accessor: "RoleID",
        Cell: ({ value }) => (value === 1 ? "Admin" : "Support"),
      },
      {
        Header: "Email",
        accessor: "Email",
      },
      {
        Header: "Created Date",
        accessor: "AddDate",
        Cell: ({ value }) =>
          value ? UserAddDateToMMDDYYYY(value) : "No date provided",
      },
      {
        Header: "Is Active",
        accessor: "IsActive",
        Cell: ({ value }) => (value ? "Yes" : "No"),
      },
    ],
    []
  );

  const fetchPaginatedData = async (
    pageIndex,
    pageSize,
    sortBy,
    searchCriteria
  ) => {
    try {
      const accessToken = await getAccessToken();

      let sortParam = sortBy.length > 0 ? sortBy[0].id : null;
      let sortDirection = sortBy.length > 0 && sortBy[0].desc ? "DESC" : "ASC";

      let filteredCriteria = {};
      if (searchCriteria) {
        filteredCriteria = Object.entries(searchCriteria).reduce(
          (acc, [key, value]) => {
            if (value) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );
      }

      let queryParams = new URLSearchParams({
        page: pageIndex + 1,
        pageSize: pageSize,
        ...filteredCriteria,
      });

      if (sortParam) {
        queryParams.append("sort", sortParam);
        queryParams.append("sortDirection", sortDirection);
      }

      const url = `/users?${queryParams.toString()}`;

      const response = await dbInstance.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error);
      return { data: [], pageInfo: { totalCount: 0 } };
    }
  };

  const navigate = useNavigate();

  const handleRowClick = (userId) => {
    navigate(`/UserDetail/${userId}`);
  };

  const handleCreateNewUser = () => {
    navigate("/UserDetail");
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPage, sortBy: [] },
      manualPagination: true,
      manualSortBy: true,
      pageCount: pageCount,
    },
    useSortBy,
    usePagination
  );

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sortBy, searchCriteria }) => {
      setLoading(true);
      try {
        const responseData = await fetchPaginatedData(
          pageIndex,
          pageSize,
          sortBy,
          searchCriteria
        );
        setData(responseData.data);
        setPageCount(responseData.pageInfo.totalPages);
        setCurrentPage(pageIndex);
        setTotalRecords(responseData.pageInfo.totalCount);
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    const isCriteriaCleared = Object.values(searchCriteria).every(
      (value) => value === ""
    );
    if (isCriteriaCleared) {
      fetchData({ pageSize: localPageSize, pageIndex: currentPage, sortBy });
    }
  }, [searchCriteria, sortBy]);

  const handleClear = () => {
    setSearchCriteria({
      FirstName: "",
      LastName: "",
      Username: "",
      Email: "",
      IsActive: "",
    });
  };

  const handleSearchSubmit = () => {
    fetchData({
      pageSize: localPageSize,
      pageIndex: 0,
      sortBy: [],
      searchCriteria: searchCriteria,
    });
  };

  const canNextPage = currentPage < pageCount - 1;
  const canPreviousPage = currentPage > 0;
  const handleChangePageSize = (newPageSize) => {
    setLocalPageSize(newPageSize);

    // Calculate the new total number of pages
    const newTotalPages = Math.ceil(totalRecords / newPageSize);

    // Check if the current page index exceeds the new total pages
    const newCurrentPage =
      currentPage >= newTotalPages ? newTotalPages - 1 : currentPage;

    // Update the current page to ensure it's within the new range
    setCurrentPage(newCurrentPage);

    // Fetch data with the new page size and adjusted current page
    fetchData({
      pageSize: newPageSize,
      pageIndex: newCurrentPage,
      sortBy,
      searchCriteria, // Pass the current search criteria
    });
  };

  return (
    <>
      <div className='main-content'>
        <div className='content-header d-flex justify-content-between align-items-center'>
          <h1 className='page-header'>User Search</h1>
          <button
            type='button'
            className='btn btn-primary h-48 d-flex align-items-center'
            onClick={handleCreateNewUser}
          >
            <i className='fh_add icon-mr fs-5'></i>
            <span>Create User</span>
          </button>
        </div>
        <div className='card'>
          <div className='card-body'>
            <div className='card-form form-content mt-0'>
              <div className='row'>
                <div className='col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4'>
                  <label htmlFor='FirstName' className='d-flex form-label'>
                    First Name
                  </label>
                  <input
                    type='text'
                    name='FirstName'
                    className='form-text form-control'
                    value={searchCriteria.FirstName}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        FirstName: e.target.value,
                      })
                    }
                    placeholder='Search by First Name'
                  />
                </div>
                <div className='col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4'>
                  <label htmlFor='LastName' className='d-flex form-label'>
                    Last Name
                  </label>
                  <input
                    type='text'
                    name='LastName'
                    className='form-text form-control'
                    value={searchCriteria.LastName}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        LastName: e.target.value,
                      })
                    }
                    placeholder='Search by Last Name'
                  />
                </div>
                <div className='col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4'>
                  <label htmlFor='Username' className='d-flex form-label'>
                    Username
                  </label>
                  <input
                    type='text'
                    name='Username'
                    className='form-text form-control'
                    value={searchCriteria.Username}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        Username: e.target.value,
                      })
                    }
                    placeholder='Search by Username'
                  />
                </div>
                <div className='col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4'>
                  <label htmlFor='Email' className='d-flex form-label'>
                    Email
                  </label>
                  <input
                    type='text'
                    name='Email'
                    className='form-text form-control'
                    value={searchCriteria.Email}
                    onChange={(e) =>
                      setSearchCriteria({
                        ...searchCriteria,
                        Email: e.target.value,
                      })
                    }
                    placeholder='Search by Email'
                  />
                </div>
                <div className='col-sm-6 col-md-4 col-xl-3 mb-4 mb-md-4'>
                  <label htmlFor='IsActive' className='d-flex form-label'>
                    Is Active
                  </label>
                  <div className='form-field position-relative'>
                    <select
                      name='IsActive'
                      className='form-select form-control'
                      value={searchCriteria.IsActive}
                      onChange={(e) =>
                        setSearchCriteria({
                          ...searchCriteria,
                          IsActive: e.target.value,
                        })
                      }
                    >
                      <option value=''>All</option>
                      <option value='1'>Yes</option>
                      <option value='0'>No</option>
                    </select>
                    <span className='field-icon position-absolute top-50 translate-middle-y'>
                      <i className='fh_arrow_down_line'></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-12 text-center'>
                <button
                  type='button'
                  className='btn btn-primary h-48 me-3'
                  onClick={handleSearchSubmit}
                >
                  Search
                </button>
                <button
                  type='button'
                  className='btn btn-border-primary h-48'
                  onClick={handleClear}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className='card-content'>
              <div className='content-subheader d-flex align-items-center'>
                <h2 className='page-subheader me-3'>User List </h2>
                <span className='total-record'>
                  [ Total Records: {totalRecords} ]
                </span>
              </div>
              <div className='table-responsive'>
                <table {...getTableProps()} className='table caption-top'>
                  <thead>
                    {headerGroups.length > 0 &&
                      headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              <span className='ms-2 position-relative top-2'>
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className='fh_dsc'></i>
                                  ) : (
                                    <i className='fh_asc'></i>
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          ))}
                        </tr>
                      ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {loading ? (
                      <tr>
                        <td
                          colSpan={columns.length}
                          className='text-center my-4'
                        >
                          <div className='spinner-border' role='status'></div>
                        </td>
                      </tr>
                    ) : data.length === 0 ? (
                      <tr>
                        <td colSpan={columns.length} className='text-center'>
                          No data found
                        </td>
                      </tr>
                    ) : (
                      page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <nav
                aria-label='Page navigation'
                className='d-flex align-items-center'
              >
                <span className='pagnination-info me-2'>
                  Page{" "}
                  <strong>
                    {currentPage + 1} of {pageCount}
                  </strong>
                </span>
                <ul className='pagination d-flex justify-content-end mb-0'>
                  <li className='page-item'>
                    <button
                      className='page-link'
                      onClick={() =>
                        fetchData({
                          pageSize: localPageSize,
                          pageIndex: 0,
                          sortBy,
                        })
                      }
                      disabled={!canPreviousPage}
                    >
                      <i className='fh_double_arrow_left'></i>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button
                      className='page-link'
                      onClick={() =>
                        fetchData({
                          pageSize: localPageSize,
                          pageIndex: currentPage - 1,
                          sortBy,
                        })
                      }
                      disabled={!canPreviousPage}
                    >
                      <i className='fh_arrow_left_line'></i>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button
                      className='page-link'
                      onClick={() =>
                        fetchData({
                          pageSize: localPageSize,
                          pageIndex: currentPage + 1,
                          sortBy,
                        })
                      }
                      disabled={!canNextPage}
                    >
                      <i className='fh_arrow_right_line'></i>
                    </button>
                  </li>
                  <li className='page-item'>
                    <button
                      className='page-link'
                      onClick={() =>
                        fetchData({
                          pageSize: localPageSize,
                          pageIndex: pageCount - 1,
                          sortBy,
                        })
                      }
                      disabled={!canNextPage}
                    >
                      <i className='fh_double_arrow_right'></i>
                    </button>
                  </li>
                </ul>
              </nav>
              <div>
                <select
                  className='form-select form-control cursor-pointer custom-table-dropdown'
                  value={localPageSize}
                  onChange={(e) => handleChangePageSize(Number(e.target.value))}
                >
                  {[10, 20, 30].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSearch;
